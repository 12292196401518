<template>
  <!-- 员工列表 -->
  <div class="userList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>员工管理</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row :gutter="20" class="mt20">
      <el-col :span="6" class="flex flex_align_center flex_between w2">
        <span>姓名</span>
        <el-input v-model="params.name"></el-input>
      </el-col>
      <el-col :span="6" class="flex flex_align_center flex_between w2">
        <span>工号</span>
        <el-input v-model="params.no"></el-input>
      </el-col>
      <el-col :span="6" class="flex flex_align_center flex_between w3">
        <span>用户状态</span>
        <el-select v-model="params.isEnabled">
          <el-option label="全部" value=""></el-option>
          <el-option label="启用" :value="0"></el-option>
          <el-option label="禁用" :value="1"></el-option>
        </el-select>
      </el-col>
      <el-col :span="2">
        <el-button type="primary" icon="el-icon-search" @click="search" plain>搜索</el-button>
      </el-col>
    </el-row>
    <div class="mt10 mb20">
      <el-button type="primary" icon="el-icon-circle-plus-outline" @click="handleUser('add')" plain>新增</el-button>
    </div>
    <el-table :data="userList" border stripe class="table-mainbg mt20">
      <el-table-column prop="no" label="工号"></el-table-column>
      <el-table-column prop="name" label="姓名"></el-table-column>
      <el-table-column prop="mobile" label="电话"></el-table-column>
      <!-- <el-table-column prop="work" label="工作台名称"></el-table-column> -->
      <el-table-column label="状态" align="center">
        <template slot-scope="scope">
          <el-switch :value="scope.row.isEnabled==0?true:false"></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="createDate" label="创建日期"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button :type="scope.row.staffStatus==='off'?'warning':'danger'" size="mini" plain  @click="onDepart(scope.row)">{{scope.row.staffStatus==="off"?'在职':'离职'}}</el-button>
          <el-button type="primary" size="mini" plain icon="el-icon-edit" @click="handleUser('edit',scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="page_size"
      :current-page="page_index"
      :total="page_total"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      params: {
        name: '',
        isEnabled: ''
      },
      userList: [],
      page_index: 1, // 页数
      page_total: 0, // 总页数
      page_size: 10,
    }
  },
  created() {
    this.getQueryUserPage();
  },
  methods: {
    onDepart(row){
      this.$confirm(`当前员工是否${row.staffStatus==="off"?'在职':'离职'}?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let query={
          staffStatus:row.staffStatus==="off"?'on':'off'
        }
        if(row.staffStatus==="on"){
          query.isEnabled=1;
        }else{
          query.isEnabled=0;
        }
        this.$api.userUpdate(row.id,this.$$.delete_null_properties(query)).then(res => {
          if(res.code == 0){
            this.getQueryUserPage();
            this.$message.success(res.message);
          }
        })
      }).catch(() => {});
    },
    getQueryUserPage() {
      let params = {
        no:this.params.no,
        name: this.params.name,
        isEnabled: this.params.isEnabled,
        curPage: this.page_index,
        pageSize: this.page_size,
      }
      this.$api.getQueryUserPage(this.$$.delete_null_properties(params)).then(res => {
        if(res.code == 0){
          this.userList = res.data.records;
          this.page_total = res.data.total;
          this.userList.forEach(item => {
            item.roleName = '';
            if(item.roleList && item.roleList.length){
              item.roleList.forEach(i => {
                item.roleName += i.name
              })
            }
          })
        }
      })
    },
    search() {
      this.page_index = 1;
      this.getQueryUserPage()
    },
    handleUser(type, row) {
      if(type === 'add'){
        let pathData=this.$router.resolve({
          path: '/admin/staff/user/handleUser', 
          query: {type: type}
        })
        window.open(pathData.href,'_blank')
      }else if(type === 'edit'){
        let pathData1=this.$router.resolve({
          path: '/admin/staff/user/handleUser', 
          query: {type: type, no: row.no, name: row.name,id:row.id}
        })
        window.open(pathData1.href,'_blank')
      }
    },
    handleSizeChange(row){
      this.page_size=row;
      this.page_index=1;
      this.getQueryUserPage()
    },
    handleCurrentChange(row){
      this.page_index=row;
      this.getQueryUserPage()
    },
  }
}
</script>

<style scoped lang="scss">
.userList{
  padding: 20px 40px;
}
</style>