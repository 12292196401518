<template>
  <div class="pageContainer">
    <el-row :gutter="24" class="pageRowBox">
      <el-col :span="6" class="flex flex_align_center flex_between w5 mb10 rowW">
        <span>投保商户姓名</span>
        <el-input v-model="form.merchantName" size="medium"></el-input>
      </el-col>
      <el-col :span="6" class="flex flex_align_center flex_between w4 mb10 rowW">
        <span>维护专员</span>
        <el-select v-model="form.maintainerName" placeholder="请选择" size="medium" clearable>
          <el-option v-for="item in employlist" :key="item.index" :label="item.name" :value="item.name"></el-option>
        </el-select>
      </el-col>
      <el-col :span="6" class="flex flex_align_center w4 mb10">
        <el-button type="primary" @click="onsearch" size="medium" plain>查询</el-button>
        <el-button type="primary" @click="onexamine(3)" size="medium" plain>批量修改截止日期</el-button>
      </el-col>
    </el-row>
    <el-table :data="querylist" border stripe class="mt10 table-mainbg" @selection-change="handleSelectionChange">
      <el-table-column
      type="selection" align="center"
      width="55">
    </el-table-column>
      <el-table-column prop="merchantId" label="投保商户Id" align="center"></el-table-column>
      <el-table-column prop="merchantName" label="投保商户姓名" align="center">
        <template slot-scope="scope">{{scope.row.merchant?scope.row.merchant.merchantName:scope.row.merchantName}}</template>
      </el-table-column>
      <el-table-column prop="registerCityName" label="注册城市" align="center">
        <template slot-scope="scope">{{scope.row.merchant?scope.row.merchant.registerCityName:scope.row.registerCityName}}</template>
      </el-table-column>
      <el-table-column prop="businessCityName" label="经营城市" align="center">
        <template slot-scope="scope">{{scope.row.merchant?scope.row.merchant.businessCityName:scope.row.businessCityName}}</template>
      </el-table-column>
      <el-table-column prop="name" label="开通险种" align="center">
        <template slot-scope="scope">{{scope.row.insure?scope.row.insure.name:scope.row.name}}</template>
      </el-table-column>
      <el-table-column prop="insureStatus" label="保单状态" align="center">
        <template slot-scope="scope">{{scope.row.insureStatus==1?'待生效':scope.row.insureStatus===2?'生效中':scope.row.insureStatus==3?'已失效':scope.row.insureStatus==4?'无效':scope.row.insureStatus}}</template>
      </el-table-column>
      <el-table-column prop="enableTime" label="生效时间" align="center"></el-table-column>
      <el-table-column prop="disableTime" label="截至时间" align="center"></el-table-column>
      <el-table-column prop="maintainerName" label="维护专员" align="center">
        <template slot-scope="scope">{{scope.row.merchant?scope.row.merchant.maintainerName:scope.row.maintainerName}}</template>
      </el-table-column>
      <el-table-column label="操作" width="240" align="center" >
        <template slot-scope="scope" >
          <el-button
            type="primary"
            @click="onexamine(1,scope.row)"
            size="mini"
          >查看详情</el-button>
          <el-button
            type="primary"
            @click="onexamine(2,scope.row)"
            size="mini"
          >修改截止日期</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="total"
    ></el-pagination>

    <el-dialog
      width="500px"
      :show-close="false"
      class="customDialog"
      :visible.sync="dialogVisible"
      title="修改保单截止日期"
    >
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
      >
        <el-form-item label="保单时间" prop="disableTime" >
          <el-date-picker
            type="date"
            placeholder="选择日期" 
            v-model="form.disableTime" value-format="yyyy-MM-dd"
            size="medium" :picker-options="pickerOptions"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="medium">取消</el-button>
        <el-button type="primary" size="medium" @click="onSubmit('form')">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form:{
        isEnabled:'',
      },
      querylist: [],
      total: 0,
      currentPage: 1,
      pageSize: 10,
      rules: {
        disableTime: [{ required: true, message: "请选择" }],
      },
      pickerOptions:{
        disabledDate(date) {//禁止选择当前日期之前的日期（今天可选）
          return date.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
      },
      dialogVisible:false,
      merchantId:'',
      itemRow:{},
      selectedlist:[],//选中的数据
      employlist:[],
    };
  },
  created() {
    if(this.$route.query.id) this.merchantId = this.$route.query.id;
    this.init();
    this.queryUserListByRoleAndDepart()//维护业务员
  },
  methods: {
    handleSelectionChange(e){
      this.selectedlist=e;
    },
    init() {
      let query = {
        "curPage": this.currentPage, //*当前页
        "merchantName": this.form.merchantName,//商户姓名
        "pageSize": this.pageSize,//每页条数
        maintainerName:this.form.maintainerName,
      };
      this.$api.postPageMerchantInsure(this.$$.delete_null_properties(query)).then(res => {
        if (res) {
          if (res.code == 0) {
            this.querylist = res.data.records;
            this.total = res.data.total;
          }else{
            this.querylist = [];
            this.total = 0;
          }
        }
      });
    },
    onexamine(type,row){
      if(row){
        this.itemRow=JSON.parse(JSON.stringify(row))
      }else{
        this.itemRow={}
      }
      switch(type){
        case 1://查看详情
          this.$router.push({
            path:'/merchant/insuredMerchantDetails',
            query:{
              id:row.merchantId
            }
          })
          break;
        case 2://单个处理
          this.dialogVisible=true;
          break;
        case 3://批量处理
          if(this.selectedlist.length==0){
            this.$message.warning('最少需要选择一条数据！')
            return
          }
          this.dialogVisible=true;
          break;
      }
    },
    onSubmit(formdata){
      this.$refs[formdata].validate(valid => {
        if (valid) {
          let merchantIds='';
          if(this.itemRow.merchant){
            merchantIds=[this.itemRow.merchant.id];
          }else{
            merchantIds=this.selectedlist.map(i=> {return i.merchant.id})
          }
          let query={
            merchantIds:merchantIds,
            disableTime:this.form.disableTime?`${this.form.disableTime} 23:59:59`:this.form.disableTime
          }
          this.$api.postUpdateDisableTime(query).then(res=>{
            if(res.code===0&&res.data===true){
              this.dialogVisible=false;
              this.$message.success(res.message)
              this.init()
            }
          })
        }
      });
    },
    onsearch() {
      this.currentPage=1;
      this.init();
    },
    handleSizeChange(data) {
      //切换条数
      this.pageSize = data;
      this.currentPage=1;
      this.init()
    },
    handleCurrentChange(data) {
      //切换页数
      this.currentPage = data;
      this.init()
    },
    queryUserListByRoleAndDepart(){//维护业务员
      let query={
        "roleName": "销售专员"
      }
      this.$api.queryUserListByRoleAndDepart(query).then((res) => {
        if(res.code==0){
          this.employlist=res.data
        }
      })
    },
  }
};
</script>
<style lang="scss" scoped>
.pageRowBox{
  display:flex;
  flex-wrap:wrap;
}
@media (max-width: 1600px) {
  .rowW{
    width:300px;
  }
}
@media (max-width: 1400px) {
  .rowW{
    width:350px;
  }
}
</style>
