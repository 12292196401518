<template>
  <!-- 新增/编辑用户 -->
  <div class="handleUser">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>员工管理</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>{{pageTitle}}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="title_top_nameBox mt20 mb10">基本信息</div>
    <el-row :gutter="20" class="mt20">
      <el-col :span="6" class="flex flex_align_center flex_between w2">
        <span>工号 :</span>
        <el-input clearable v-model="params.no"></el-input>
      </el-col>
      <el-col :span="6" class="flex flex_align_center flex_between w2">
        <span>姓名 :</span>
        <el-input clearable v-model="params.name"></el-input>
      </el-col>
      <el-col :span="6" class="flex flex_align_center flex_between w2">
        <span>电话 :</span>
        <el-input clearable v-model="params.mobile"></el-input>
      </el-col>
      <el-col :span="2">
        <el-button type="primary" icon="el-icon-search" @click="search" plain>查询</el-button>
      </el-col>
    </el-row>
    <div class="mt20">
      <span class="mr40">工号：{{userInfo.no}}</span>
      <span class="mr40">姓名：{{userInfo.name}}</span>
      <span class="mr40">电话：{{userInfo.mobile}}</span>
      <span class="mr40">状态：
        <el-radio-group v-model="userInfo.isEnabled">
          <el-radio :label="0">激活</el-radio>
          <el-radio :label="1">禁用</el-radio>
        </el-radio-group>
      </span>
    </div>
    <div class="title_top_nameBox mt20">岗位信息</div>
    <div v-for="(item,index) in userInfo.dataRelaList" :key="index" class="mt10 flex">
      <span class="mr20">岗位{{index+1}}
        <el-select v-model="item.userRole.roleId" clearable style="width:180px;" placeholder="请选择角色" @input="changeUpdate">
          <el-option v-for="items in roleList" :key="items.id" :value="items.id" :label="items.name"></el-option>
        </el-select>
      </span>
      <span class="mr20 flex">
        <div class="treeBox">
          <div class="treeText">数据权限</div>
          <!-- <el-input
            placeholder="输入关键字进行过滤"
            v-model="filterText">
          </el-input> -->
          <div class="treePosBox">
            <el-tree class="treeModelBox"
              :ref="`tree_${index}`" 
              :data="departmentList"
              show-checkbox 
              node-key="id"
              default-expand-all
              :props="defaultProps"
              @check="changeTree(`tree_${index}`,index,item)"
              >
            </el-tree>
          </div>
        </div>
      </span>
      <el-checkbox class="mr40" :value="Boolean(item.userRole.dataRange)" @input="changeUpdate" @change="((val)=>{changeDataRange(val, index)})">只看本人的数据</el-checkbox>
      <el-button  type="danger" size="mini" icon="el-icon-delete" class="deleteBut" circle @click="deleteRole(index)"></el-button>
    </div>
    <div class="mt20"><el-button type="primary" icon="el-icon-plus" @click="addRole"></el-button></div>

    <div class="footer">
      <div class="btn">
        <el-button type="primary" @click="submitEdit">提交</el-button>
      </div>
    </div>

    <!-- 用户查询弹窗 -->
    <el-dialog title="员工" :visible.sync="dialogTableVisible" center>
      <el-table :data="userList" ref="singleTable" border @current-change="handleCurrentChange" highlight-current-row>
        <el-table-column type="index"></el-table-column>
        <el-table-column property="no" label="工号"></el-table-column>
        <el-table-column property="name" label="姓名"></el-table-column>
        <el-table-column property="mobile" label="电话"></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogTableVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitCurRow">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      roleList: [],
      userList: [],
      pageTitle: '',
      params: {
        no: '',
        name: '',
        mobile: '',
      },
      userInfo:{
        no: '',
        name: '',
        mobile: '',
        isEnabled: '',
        sysUserRoleList: [{}]
      },
      defaultProps: {
        children: 'subDepartList',
        label: 'name'
      },
      currentRow: {},
      dialogTableVisible: false,
      multipleSelection: [],
      // citylist:[],//城市列表
      // branchOffice:[],//分公司
      departmentList:[],//部门列表
      // shoplist:[],//门店列表
      shopId:[],
      type:'edit',//是编辑还是新增
      filterText:'',
      id:'',
    }
  },
  watch: {
    filterText(val) {
      if(this.$refs.tree){
        this.$refs.tree.filter(val);
      }
    }
  },
  mounted() {
    if(this.$route.query.type && this.$route.query.type === 'add'){
     this.$route.meta.title = document.title = this.pageTitle = '新增用户';
     this.bus.$emit('updateVisitedTitle', '新增用户')
    }else if(this.$route.query.type && this.$route.query.type === 'edit'){
     this.$route.meta.title = document.title = this.pageTitle = '编辑用户';
     this.bus.$emit('updateVisitedTitle', '编辑用户')
     this.params.no = this.$route.query.no;
     this.params.name = this.$route.query.name;
     this.id = this.$route.query.id;
     this.getUserData();
    }
  },
  created() {
    if(this.$route.query.type) this.type=this.$route.query.type;
    this.postRoleList()//获取所有角色
    this.getRegionByConditions();//获取城市
    // this.departmentQuery(1);//获取分公司
    // this.departmentQuery(2);//获取部门
    // this.departmentQuery(3);//获取门店
    this.postDepartmentList();//递归
  },
  methods: {
    changeUpdate(){
      this.$forceUpdate()
    },
    postRoleList() {//获取所有角色
      this.$api.postRoleList({}).then(res => {
        this.roleList = res.data;
      })
    },
    getUserData() {
      this.$api.getUserData(this.$$.delete_null_properties(this.id)).then(res => {
        if(res.code == 0){
          this.userInfo = res.data;
          if(res.data.dataRelaList) for(let j=0;j<res.data.dataRelaList.length;j++){
            let ii=res.data.dataRelaList[j]
            ii.userRole=ii.userRole?ii.userRole:{};
            let newarr=[];
            for(let a=0;a<ii.userDeptList.length;a++){
              let ab=ii.userDeptList[a]
              newarr.push(ab.departmentId)
            }
            ii.dataId=newarr;
            this.$nextTick(()=>{
              if(this.$refs[`tree_${j}`]){
                this.$refs[`tree_${j}`][0].setCheckedKeys(newarr)
              }
            })
          }
        }
      })
    },
    search() {
      this.getUserData();
    },
    handleCurrentChange(val) {
      this.currentRow = val;
    },
    submitCurRow() {
      this.userInfo = this.currentRow;
      this.dialogTableVisible = false;
      this.userList = [];
    },
    deleteRole(index) {
      this.userInfo.dataRelaList.splice(index,1)
      this.$forceUpdate()
    },
    addRole() {
      if(!this.userInfo.dataRelaList){
        this.userInfo.dataRelaList=[]
      }
      this.userInfo.dataRelaList.push({dataId: [], dataRange: 0,userRole:{dataRange:0}})
      this.$nextTick(()=>{
        this.$forceUpdate()
      })
      // this.userInfo.sysUserRoleList.push({roleId: '', companyId: '',cityId: '',departmentId: '',shopIds: '', dataRange: 0})
    },
    changeDataRange(val,index) {
      if(val){
        this.userInfo.dataRelaList[index].userRole.dataRange = 1;
      }else{
        this.userInfo.dataRelaList[index].userRole.dataRange = 0;
      }
    },
    changeTree(ref,index) {
      let checkKey = this.$refs[ref][0].getCheckedKeys();
      for(let i=0;i<this.userInfo.dataRelaList.length;i++){
        let item=this.userInfo.dataRelaList[i]
        if(index==i){
          item.dataId=checkKey;
        }
      }
    },
    submitEdit() {
      let arr=[]
      if(this.userInfo.dataRelaList){
        for(let i=0;i<this.userInfo.dataRelaList.length;i++){
          let item=this.userInfo.dataRelaList[i]
          let obj={
              "roleId": item.userRole.roleId,
              "dataRange": item.userRole.dataRange,
              deptIds:item.dataId
            }
            arr.push(obj)
        }
      }
      
      
      if(this.type=='edit'){
        let query={
          "name": this.userInfo.name,
          "mobile": this.userInfo.mobile,
          "no": this.userInfo.no,
          "email": this.userInfo.email,
          "userType": this.userInfo.userType,
          "photo": this.userInfo.photo,
          "pwdExpired": this.userInfo.pwdExpired,
          "isEnabled":this.userInfo.isEnabled,
          "remark": this.userInfo.remark,
          "userRoleDeptList": arr,
        }
        this.$api.userUpdate(this.userInfo.id,this.$$.delete_null_properties(query)).then(res => {
          if(res.code == 0){
            this.getUserData();
            this.$message.success(res.message);
          }
        })
      }else{
        // this.$message.warning('不可新增用户！')
        if(!this.params.name){
          this.$message.warning('姓名不可为空！')
          return
        }
        if(!this.params.mobile){
          this.$message.warning('手机号不可为空！')
          return
        }
        if(!this.params.no){
          this.$message.warning('工号不可为空！')
          return
        }
        let query={
          "name": this.params.name,
          "mobile": this.params.mobile,
          "no": this.params.no,
          "userType": 1,//用户类型，1:员工，2商户，默认1
          "userRoleDeptList": arr,
        }
        this.$api.postUserAdd(query).then(res=>{
          if(res.code==0){
            this.$message.success(res.message);
            this.bus.$emit("closeSelected",'router');
            this.$router.push({
              path: '/admin/staff/user/userList', 
            })
          }
        })
      }
    },
    getRegionByConditions(){//获取城市
      let query={
        level:2
      }
      this.$api.getRegionByConditions(query).then(res=>{
        if(res.code==0){
          this.citylist=res.data;
        }
      })
    },
    postDepartmentList(){//查询部门
      let query={
        level:0,//部门层级, 总公司0，分公司1，部门2，子部门3.4.5等往下递进
        isEnabled:0
      }
      this.$api.postDepartmentList(query).then(res=>{
        if(res.code==0){
          this.departmentList=res.data//部门列表
          // if(level==1){
          //   this.branchOffice=res.data//分公司
          // }else if(level==2){
          //   this.departmentList=res.data//部门列表
          // }else if(level==3){
          //   this.shoplist=res.data//门店列表
          // }
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.handleUser{
  padding: 20px 20px 140px;
  .mr40{margin-right: 40px;}
  .treeBox{
    display:flex;
    position: relative;
    // width:300px;
    margin-top: 8px;
    // .treeText{
    //   margin-top:10px;
    // }
    // .treePosBox{
    //   position: absolute;
    //   top: 10px;
    //   left:70px;
    //   background-color:#ffffff;
    // }
    // .treeModelBox{
    //   max-height:500px;
    //   overflow:auto;
      
    // }
  }
  .deleteBut{
    width: 40px;
    height: 40px;
  }
}
</style>