<template>
  <!-- 商户预约 -->
  <div class="contenPageBox">
    <el-row :gutter="24">
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w4 mb10" >
        <span>车牌号</span>
        <el-input v-model="params.carNo" size="medium"></el-input>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w4 mb10" >
        <span>4S店简称</span>
        <el-input v-model="params.vin" size="medium"></el-input>
      </el-col>
      <el-col :xl="4" :lg="6" class="flex flex_align_center flex_between w4 mb10" >
        <span>订单状态</span>
        <el-select v-model="params.submitFlag" size="medium" clearable>
          <el-option label="退回待处理" :value="0"></el-option>
          <el-option label="退回已处理" :value="1"></el-option>
          <!-- <el-option label="身份信息退回" :value="2"></el-option>
          <el-option label="时间信息退回" :value="3"></el-option> -->
        </el-select>
      </el-col>
      <el-col :xl="2" :lg="2"  class="mb10">
        <el-button type="primary" size="medium" @click="search" plain>查询</el-button>
      </el-col>
    </el-row>
    
    <el-table :data="querylist" border class="mt10 table-mainbg" >
      <el-table-column prop="taskId" align="center" label="任务ID" ></el-table-column>
      <el-table-column prop="vin" align="center" label="车架号" ></el-table-column>
      <el-table-column prop="carNo" align="center" label="原车牌号" ></el-table-column>
      <el-table-column prop="modifiedLicenseNo" align="center" label="修改后的车牌号" ></el-table-column>
      <el-table-column prop="vin" align="center" label="4S店简称" ></el-table-column>
      <el-table-column prop="returnType" align="center" label="退回类型" :formatter="formatterReturn"></el-table-column>
      <el-table-column prop="returnReason" align="center" label="退回原因" :formatter="formatterReturn"></el-table-column>
      <el-table-column prop="returnTime" align="center" label="退回时间" ></el-table-column>
      <el-table-column prop="returnInfo" label="订单状态" align="center"  >
        <template slot-scope="scope">{{scope.row.submitFlag===0?"退回待处理":scope.row.submitFlag===1?"退回已处理":scope.row.submitFlag}}</template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="150px" >
        <template slot-scope="scope">
          <el-button size="mini" type="primary" plain @click="onOperate(scope.row,0)" v-if="scope.row.submitFlag===0">去处理</el-button>
          <el-button size="mini" type="primary" plain @click="onOperate(scope.row,1)" v-else>查看详情</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="page_size"
      :current-page="page_index"
      :total="page_total"
    ></el-pagination>

   
  </div>
</template>

<script>
export default {
  data() {
    return {
      params: {
        carNo: '',
        vin: '',
        submitFlag:0,
      },
      querylist: [],
      page_index: 1, // 页数
      page_total: 0, // 总页数
      page_size: 10,
      itemRow:{},
    }
  },
  created() {
    this.initData();
  },
  methods: {
    onOperate(row,state){
      this.$router.push({
        path:'/BMW/BMWreturnCarDateils',
        query:{
          taskId:row.taskId,
          vin:row.vin,
          state:state
        }
      })
    },
    formatterReturn(row,column){
      let returnInfo=row.returnInfo?JSON.parse(row.returnInfo):[]
      if(returnInfo.length>0){
        let str=[]
        let stt=[]
        for(let i=0;i<returnInfo.length;i++){
          if(column.label==="退回类型"){
            if(returnInfo[i].returnType==0){
              str.push('其他信息退回')
            }else if(returnInfo[i].returnType==1){
              str.push('凭证退回')
            }else if(returnInfo[i].returnType==2){
              str.push('身份信息退回')
            }else if(returnInfo[i].returnType==3){
              str.push('时间信息退回')
            }
          }else{
            stt.push(returnInfo[i].returnReason)
          }
        }
        if(column.label==="退回类型"){
          return String(str)
        }else{
          return String(stt)
        }
      }
    },
    handleSizeChange(row){
      this.page_size=row;
      this.page_index=1;
      this.initData()
    },
    handleCurrentChange(row){
      this.page_index=row;
      this.initData()
    },
    search() {
      this.page_index = 1;
      this.initData() ;
    },
    initData() {
      let query={
        curPage: this.page_index,
        pageSize: this.page_size, 
        carNo:this.params.carNo,
        vin:this.params.vin,
        submitFlag:this.params.submitFlag,
      }
     this.$api.getSendReturnList(this.$$.delete_null_properties(query)).then(res => {
        if (res) {
          if (res.code == 0) {
            this.querylist = res.data.records;
            this.page_total = res.data.total;
          } else {
            this.querylist = [];
            this.page_total = 0;
          }
        }
      });
      
    },
  },
}
</script>

<style scoped lang="scss">
.contenPageBox{
  padding: 20px 40px;
}
</style>
