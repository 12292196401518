<template>
  <!-- 宝马合作车辆拍卖结果 -->
  <div class="contenPageBox" >
    <div class="ckxTitle mb15">退回信息</div>
    <table class="table-oddwholebg table-custom" >
      <tbody>
        <tr>
          <td>任务ID</td><td>{{res.taskId}}</td>
          <td>车架号</td><td>{{res.vin}}</td>
          <td>原车牌号</td><td>{{res.carNo}}</td>
          <td>修改后车牌号</td><td>{{res.modifiedLicenseNo}}</td>
        </tr>
        <tr>
          <td>退回类型</td><td>{{formatterReturn('退回类型')}}</td>
          <td>退回原因</td><td>{{formatterReturn('退回原因')}}</td>
          <td>退回时间</td><td>{{res.returnTime}}</td>
          <td></td><td></td>
        </tr>
      </tbody>
    </table>
    <div class="ckxTitle mb15 mt20">检测项修改重传项</div>
    <table class="table-custom mt20 tableLine" >
      <thead>
        <th>选中修改</th>
        <th>字值名称</th>
        <th>值</th>
      </thead>
      <tbody>
        <tr v-for="item in listTest" :key="item.id">
          <td>
            <el-checkbox v-if="item.type==='checkbox'" v-model="item.checked"></el-checkbox>
          </td>
          <td>{{item.name}}（{{item.field}}）</td>
          <td>
            <template v-if="item.checked">
              <template v-if="item.dataType==='date'">
                 <el-date-picker v-if="!['出厂日期'].includes(item.name)"
                  v-model="item.value"
                  type="date" size="medium"
                  placeholder="选择日期"
                  value-format="yyyy/MM/dd">
                </el-date-picker>
                <el-date-picker v-else
                  v-model="item.value"
                  type="date" size="medium"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd">
                </el-date-picker>
              </template>
           <el-date-picker v-else-if="item.dataType==='datetime'"
                  v-model="item.value"
                  type="datetime" size="medium"
                  placeholder="选择日期"
                  value-format="yyyy/MM/dd HH:mm:ss">
            </el-date-picker>
            <el-input v-else-if="item.dataType==='input'"
              placeholder="请输入" size="medium"
              v-model="item.value">
            </el-input>
            <el-select v-model="item.value" placeholder="请选择"  size="medium" v-else-if="item.dataType==='select'">
              <template v-if="['动力类型'].includes(item.name)">
                <el-option label="混动" value="混动"></el-option>
                <el-option label="燃油" value="燃油"></el-option>
                <el-option label="纯电" value="纯电"></el-option>
                <el-option label="检测人员录入" value="检测人员录入"></el-option>
              </template>
              <template v-if="['是否在经销商名下'].includes(item.name)">
                <el-option label="否" value="N"></el-option>
                <el-option label="是" value="Y"></el-option>
              </template>
            </el-select>
            </template>
            <p>{{item.value1}}</p>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="ckxTitle mb15 mt20">拍卖结果项修改重传项</div>
    <table class="table-custom mt20 tableLine" >
      <thead>
        <th>选中修改</th>
        <th>字值名称</th>
        <th>值</th>
      </thead>
      <tbody>
        <tr v-for="item in listTest1" :key="item.id">
          <td>
            <el-checkbox v-if="item.type==='checkbox'" v-model="item.checked"></el-checkbox>
          </td>
          <td>{{item.name}}（{{item.field}}）</td>
          <td>
            <template v-if="item.checked">
            <el-date-picker v-if="item.dataType==='date'"
              v-model="item.value"
              type="date" size="medium"
              placeholder="选择日期"
              value-format="yyyy-MM-dd">
            </el-date-picker>
            <el-date-picker v-else-if="item.dataType==='datetime'"
                  v-model="item.value"
                  type="datetime" size="medium"
                  placeholder="选择日期"
                  value-format="yyyy/MM/dd HH:mm:ss">
            </el-date-picker>
            <el-input v-else-if="item.dataType==='input'"
              placeholder="请输入" size="medium"
              v-model="item.value">
              <!-- <template slot="append">次</template> -->
            </el-input>
            <el-select v-model="item.value" placeholder="请选择"  size="medium" v-else-if="item.dataType==='select'">
              <template v-if="['成交状态'].includes(item.name)">
                <el-option label="成交" value="0"></el-option>
                <el-option label="流拍" value="1"></el-option>
                <el-option label="违约" value="2"></el-option>
              </template>
              <template v-if="['是否在经销商名下'].includes(item.name)">
                <el-option label="否" value="N"></el-option>
                <el-option label="是" value="Y"></el-option>
              </template>
              <template v-if="['原车主类型'].includes(item.name)">
                <el-option label="个人" value="0"></el-option>
                <el-option label="公司" value="1"></el-option>
              </template>
            </el-select>
            </template>
            <p>{{item.value1}}</p>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="ckxTitle mb15 mt20">结算结果项修改重传项</div>
    <table class="table-custom mt20 tableLine" >
      <thead>
        <th>选中修改</th>
        <th>字值名称</th>
        <th>值</th>
      </thead>
      <tbody>
        <tr v-for="(item,index) in listTest2" :key="item.id">
          <td>
            <el-checkbox v-if="item.type==='checkbox'" v-model="item.checked" ></el-checkbox>
          </td>
          <td>{{item.name}}（{{item.field}}）</td>
          <td>
            <el-image class="ImgBox"
              :src="item.value" v-if="item.dataType==='upload'&&item.value&&item.voucherType!='pdf'"
              :preview-src-list="[item.value]">
              <div slot="error">暂无图片</div>
            </el-image>
          <template v-if="item.checked">
            <el-date-picker v-if="item.dataType==='date'"
              v-model="item.value" 
              type="date" size="medium"
              placeholder="选择日期"
              value-format="yyyy-MM-dd">
            </el-date-picker>
            <el-date-picker v-if="item.dataType==='datetime'"
              v-model="item.value"
              type="date" size="medium"
              placeholder="选择日期"
              value-format="yyyy/MM/dd HH:mm:ss">
            </el-date-picker>
            <el-input v-else-if="item.dataType==='input'"
              placeholder="请输入" size="medium"
              v-model="item.value">
              <!-- <template slot="append">次</template> -->
            </el-input>
            <el-select v-model="item.value" placeholder="请选择"  size="medium" v-else-if="item.dataType==='select'" >
              <template v-if="['是否在经销商名下'].includes(item.name)">
                <el-option label="否" value="N"></el-option>
                <el-option label="是" value="Y"></el-option>
              </template>
              <template v-if="['原车主类型'].includes(item.name)">
                <el-option label="个人" value="0"></el-option>
                <el-option label="公司" value="1"></el-option>
              </template>
            </el-select>
            <div class="photo_row_imgBox" @click="onUpload(item)" v-else-if="item.dataType==='upload'">
              <el-upload
                action
                accept="application/pdf,image/jpeg"
                :on-change="requestUpload"
                :auto-upload="false" >
                  <el-button slot="default" type="primary" size="mini" class="group-custom" >上传图片</el-button>
                  <div slot="file" slot-scope="{file}">
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.url" alt=""
                    >
                  </div>
                </el-upload>
            </div>
            </template>
            <!-- v-if="item.dataType!=='upload'&&item.voucherType=='pdf'||!item.checked" -->
            <p >{{item.value1}}</p>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="ckxTitle mt15">退回操作日志</div>
    <el-table :data="loglist" border class="mt10 table-mainbg" >
      <el-table-column prop="taskId" align="center" label="任务ID" ></el-table-column>
      <el-table-column prop="vin" align="center" label="车架号" ></el-table-column>
      <el-table-column prop="returnTime" align="center" label="退回时间" ></el-table-column>
      <el-table-column prop="returnInfo" align="center" label="退回原因" ></el-table-column>
      <el-table-column prop="recvData" align="center" label="收到数据" ></el-table-column>
      <el-table-column prop="sendData" align="center" label="发送数据" ></el-table-column>
      <el-table-column prop="submitTime" align="center" label="提交时间" ></el-table-column>
      <el-table-column prop="submitFlag" align="center" label="是否提交" >
        <template slot-scope="scope">{{scope.row.submitFlag==1?'已提交':scope.row.submitFlag==0?'未提交':scope.row.submitFlag}}</template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt30"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, prev, pager, next,sizes, jumper"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="page_size"
      :current-page="page_index"
      :total="page_total"
    ></el-pagination>

    <div class="footer" >
      <div class="btn">
        <el-button type="primary" @click="onSubmit" >发送修改结果</el-button>
      </div>
    </div>

    <el-image-viewer v-show="showViewer" :on-close="closeViewer" :url-list="srcList" style="z-index:3000"/>
  </div>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
export default {
  components: { ElImageViewer },
  data() {
    return {
      returnType:0,//入口状态/**state=0查看没有按钮,1修改**/
      dialogVisibleCar:false,
      res:{},
      invoiceDate:'',
      listTest:[
        {id:1,type:'',name:'检测编号',field:'inspectionNo',value:'',checked:false,dataType:''},
        {id:2,type:'checkbox',name:'车牌号',field:'licenseNo',value:'',checked:false,dataType:'input'},
        {id:3,type:'checkbox',name:'上牌时间',field:'regDate',value:'',checked:false,dataType:'input'},
        {id:4,type:'',name:'品牌',field:'brand',value:'',checked:false,dataType:''},
        {id:5,type:'',name:'车系',field:'series',value:'',checked:false,dataType:''},
        {id:6,type:'',name:'车型',field:'modelNameCN',value:'',checked:false,dataType:''},
        {id:7,type:'',name:'车款',field:'variantNameCN',value:'',checked:false,dataType:''},
        {id:8,type:'checkbox',name:'出厂日期',field:'prodDate',value:'',checked:false,dataType:'input'},
        {id:9,type:'checkbox',name:'动力类型',field:'powerType',value:'',checked:false,dataType:'select'},
        {id:10,type:'checkbox',name:'里程',field:'milage',value:'',checked:false,dataType:'input'},
        {id:11,type:'checkbox',name:'过户日期',field:'transferDate',value:'',checked:false,dataType:'input'},
        {id:12,type:'checkbox',name:'车龄月',field:'age',value:'',checked:false,dataType:'input'},
        {id:13,type:'checkbox',name:'是否在经销商名下',field:'transferDealer',value:'',checked:false,dataType:'select'},
        {id:14,type:'checkbox',name:'检测报告URL',field:'inspRptUrl',value:'',checked:false,dataType:'input'},
        {id:15,type:'checkbox',name:'检测报告名称',field:'inspRptName',value:'',checked:false,dataType:'input'},
        {id:16,type:'checkbox',name:'检测报告下载地址',field:'inspRptDlUrl',value:'',checked:false,dataType:'input'},
        {id:17,type:'checkbox',name:'下单来源',field:'taskSource',value:'',checked:false,dataType:'input'},
        {id:18,type:'checkbox',name:'检测时间',field:'inspectionTime',value:'',checked:false,dataType:'input'},
      ],
      listTest1:[
        {id:1,type:'',name:'检测编号',field:'inspectionNo',value:'',checked:false,dataType:''},
        {id:2,type:'checkbox',name:'成交状态',field:'auctionStatus',value:'',checked:false,dataType:'select'},
        {id:3,type:'checkbox',name:'起拍价',field:'biddingStartPrice',value:'',checked:false,dataType:'input'},
        {id:4,type:'checkbox',name:'最高出价',field:'highestBid',value:'',checked:false,dataType:'input'},
        {id:5,type:'checkbox',name:'拍卖成交价',field:'auctionPrice',value:'',checked:false,dataType:'input'},
        {id:6,type:'checkbox',name:'出价次数',field:'numOfBids',value:'',checked:false,dataType:'input'},
        {id:7,type:'checkbox',name:'参拍人数',field:'auctionNumber',value:'',checked:false,dataType:'input'},
        {id:8,type:'checkbox',name:'拍卖编号',field:'biddingCode',value:'',checked:false,dataType:'input'},
        {id:9,type:'checkbox',name:'拍卖佣金',field:'auctionCommission',value:'',checked:false,dataType:'input'},
        {id:10,type:'checkbox',name:'是否在经销商名下',field:'transferDealer',value:'',checked:false,dataType:'select'},
        {id:11,type:'checkbox',name:'原车主类型',field:'preOwnerType',value:'',checked:false,dataType:'select'},
        {id:12,type:'checkbox',name:'原车主姓名/公司名称',field:'preOwnerName',value:'',checked:false,dataType:'input'},
        {id:13,type:'checkbox',name:'原车主身份证号/机构代码证',field:'preOwnerID',value:'',checked:false,dataType:'input'},
        {id:14,type:'checkbox',name:'经销商编号',field:'dealerCode',value:'',checked:false,dataType:'input'},
        {id:15,type:'checkbox',name:'起拍时间',field:'biddingStartTime',value:'',checked:false,dataType:'input'},
        {id:16,type:'checkbox',name:'拍卖结束时间',field:'biddingEndTime',value:'',checked:false,dataType:'input'},
        {id:17,type:'checkbox',name:'检测Final报告URL',field:'inspFinalRptUrl',value:'',checked:false,dataType:'input'},
        {id:18,type:'checkbox',name:'检测Final报告名称',field:'inspFinalRptName',value:'',checked:false,dataType:'input'},
        {id:19,type:'checkbox',name:'检测Final报告下载地址',field:'inspFinalRptDlUrl',value:'',checked:false,dataType:'input'},
        {id:20,type:'checkbox',name:'拍卖结果URL',field:'aucRptUrl',value:'',checked:false,dataType:'input'},
        {id:21,type:'checkbox',name:'拍卖结果名称',field:'aucRptName',value:'',checked:false,dataType:'input'},
        {id:22,type:'checkbox',name:'拍卖结果下载地址',field:'aucRptDlUrl',value:'',checked:false,dataType:'input'},
      ],
      listTest2:[
        {id:1,type:'',name:'检测编号',field:'inspectionNo',value:'',checked:false,dataType:''},
        {id:2,type:'',name:'拍卖编号',field:'biddingCode',value:'',checked:false,dataType:''},
        {id:3,type:'',name:'成交状态',field:'auctionStatus',value:'',checked:false,dataType:''},
        {id:4,type:'checkbox',name:'交易发票日期',field:'invoiceDate',value:'',checked:false,dataType:'input'},
        {id:5,type:'',name:'付款状态',field:'paymentStatus',value:'',checked:false,dataType:''},
        {id:6,type:'checkbox',name:'资金往来凭证',field:'payDlUrl[]',value:'',checked:false,dataType:'upload'},
        {id:7,type:'checkbox',name:'二手车发票',field:'payDlUrl[]',value:'',checked:false,dataType:'upload'},
        {id:8,type:'checkbox',name:'过户后二手车登记证首页',field:'payDlUrl[]',value:'',checked:false,dataType:'upload'},
        {id:9,type:'checkbox',name:'过户后二手车登记证过户页',field:'payDlUrl[]',value:'',checked:false,dataType:'upload'},
        {id:10,type:'checkbox',name:'是否在经销商名下',field:'transferDealer',value:'',checked:false,dataType:'select'},
        {id:11,type:'checkbox',name:'原车主类型',field:'preOwnerType',value:'',checked:false,dataType:'select'},
        {id:12,type:'checkbox',name:'原车主姓名/公司名称',field:'preOwnerName',value:'',checked:false,dataType:'input'},
        {id:13,type:'checkbox',name:'原车主身份证号/机构代码证',field:'preOwnerID',value:'',checked:false,dataType:'input'},
        {id:14,type:'checkbox',name:'买家付款时间',field:'buyerPayTime',value:'',checked:false,dataType:'input'},
        {id:15,type:'checkbox',name:'买家付款金额',field:'buyerPayAmount',value:'',checked:false,dataType:'input'},
        {id:16,type:'checkbox',name:'支付经销商时间',field:'payDlTime',value:'',checked:false,dataType:'input'},
        {id:17,type:'checkbox',name:'保留价',field:'reservePrice',value:'',checked:false,dataType:'input'},
        {id:18,type:'checkbox',name:'交易过户日期',field:'retailTransferDate',value:'',checked:false,dataType:'input'},
        {id:19,type:'checkbox',name:'支付金额',field:'payAmount',value:'',checked:false,dataType:'input'},
      ],
      fileList: [
        {voucherUrl:null,voucherSize:null,voucherType:null,voucherName:'资金往来凭证',voucherCode:'01'},
        {voucherUrl:null,voucherSize:null,voucherType:null,voucherName:'二手车发票',voucherCode:'02'},
        {voucherUrl:null,voucherSize:null,voucherType:null,voucherName:'过户后二手车登记证首页',voucherCode:'03'},
        {voucherUrl:null,voucherSize:null,voucherType:null,voucherName:'过户后二手车登记证过户页',voucherCode:'04'}
        // { name: "资金往来凭证",code:'01', url: "", uploader: "", uploaderDate: "" },
        // { name: "二手车发票",code:'02', url: "", uploader: "", uploaderDate: "" },
        // { name: "过户后二手车登记证首页",code:'03', url: "", uploader: "", uploaderDate: "" },
        // { name: "过户后二手车登记证过户页",code:'04', url: "", uploader: "", uploaderDate: "" }
      ], //图片
      srcList:[],
      imgurl1:{},
      imgurl2:{},
      imgurl3:{},
      imgurl4:{},
      itemRow:{},
      showViewer:false,
      dialogFlag:false,
      diaIndex:'',
      taskId:'',
      vin:'',
      loglist:[],//log列表
      page_index: 1, // 页数
      page_total: 0, // 总页数
      page_size: 10,
    }
  },
  created(){
    if(this.$route.query.state) this.returnType=Number(this.$route.query.state);
    if(this.$route.query.taskId) this.taskId=this.$route.query.taskId;
    if(this.$route.query.vin) this.vin=this.$route.query.vin;
    this.getSendReturnList() //列表
    this.initData()
  },
  methods: {
    initData(){
      this.getReceiveInspectionInfo()
      this.getReceiveAuctionInfo()//获取发送给宝马的竞拍信息
      this.getPaymentVoucherAndDetails()//获取发送给宝马的付款凭证信息
      this.getReceiveReturnLogList()//获取推送修复数据的日志列表
    },
    forceUpdate(){
      this.$forceUpdate()
    },
    getReceiveInspectionInfo(){//获取发送给宝马的检测信息
      let query={
        taskId:this.taskId,
        vin:this.vin
      }
      this.$api.getReceiveInspectionInfo(query).then(data=>{
        if(data.code==0){
          // this.res=res.data;
          let res=JSON.parse(data.data);
          for(let i=0;i<this.listTest.length;i++){
            let item=this.listTest[i]
            if(item.name=='检测编号'){
              item.value=res.inspectionNo;
              item.value1=res.inspectionNo;
            }else if(item.name=='车牌号'){
              item.value=res.licenseNo;
              item.value1=res.licenseNo;
            }else if(item.name=='上牌时间'){
              item.value=res.regDate;
              item.value1=res.regDate;
            }else if(item.name=='品牌'){
              item.value=res.brand;
              item.value1=res.brand;
            }else if(item.name=='车系'){
              item.value=res.series;
              item.value1=res.series;
            }else if(item.name=='车型'){
              item.value=res.modelNameCN;
              item.value1=res.modelNameCN;
            }else if(item.name=='车款'){
              item.value=res.variantNameCN;
              item.value1=res.variantNameCN;
            }else if(item.name=='出厂日期'){
              item.value=res.prodDate;
              item.value1=res.prodDate;
            }else if(item.name=='动力类型'){
              item.value=res.powerType;
              item.value1=res.powerType;
            }else if(item.name=='里程'){
              item.value=res.milage;
              item.value1=res.milage;
            }else if(item.name=='过户日期'){
              item.value=res.transferDate;
              item.value1=res.transferDate;
            }else if(item.name=='车龄月'){
              item.value=res.age;
              item.value1=res.age;
            }else if(item.name=='是否在经销商名下'){
              item.value=res.transferDealer;
              item.value1=res.transferDealer;
            }else if(item.name=='检测报告URL'){
              item.value=res.inspRptUrl;
              item.value1=res.inspRptUrl;
            }else if(item.name=='检测报告名称'){
              item.value=res.inspRptName;
              item.value1=res.inspRptName;
            }else if(item.name=='检测报告下载地址'){
              item.value=res.inspRptDlUrl;
              item.value1=res.inspRptDlUrl;
            }else if(item.name=='下单来源'){
              item.value=res.taskSource;
              item.value1=res.taskSource;
            }else if(item.name=='检测时间'){
              item.value=res.inspectionTime;
              item.value1=res.inspectionTime;
            }
          }
        }
      })
    },
    getReceiveAuctionInfo(){//获取发送给宝马的竞拍信息
      let query={
        taskId:this.taskId,
        vin:this.vin
      }
      this.$api.getReceiveAuctionInfo(query).then(data=>{
        if(data.code==0){
          // this.res=res.data;
          let res=JSON.parse(data.data);
          for(let i=0;i<this.listTest1.length;i++){
            let item=this.listTest1[i]
            if(item.name=='检测编号'){
              item.value=res.inspectionNo;
              item.value1=res.inspectionNo;
            }else if(item.name=='成交状态'){
              item.value=res.auctionStatus;
              item.value1=res.auctionStatus;
            }else if(item.name=='起拍价'){
              item.value=res.biddingStartPrice;
              item.value1=res.biddingStartPrice;
            }else if(item.name=='最高出价'){
              item.value=res.highestBid;
              item.value1=res.highestBid;
            }else if(item.name=='拍卖成交价'){
              item.value=res.auctionPrice;
              item.value1=res.auctionPrice;
            }else if(item.name=='出价次数'){
              item.value=res.numOfBids;
              item.value1=res.numOfBids;
            }else if(item.name=='参拍人数'){
              item.value=res.auctionNumber;
              item.value1=res.auctionNumber;
            }else if(item.name=='拍卖编号'){
              item.value=res.biddingCode;
              item.value1=res.biddingCode;
            }else if(item.name=='拍卖佣金'){
              item.value=res.auctionCommission;
              item.value1=res.auctionCommission;
            }else if(item.name=='是否在经销商名下'){
              item.value=res.transferDealer;
              item.value1=res.transferDealer;
            }else if(item.name=='原车主类型'){
              item.value=res.preOwnerType;
              item.value1=res.preOwnerType;
            }else if(item.name=='原车主姓名/公司名称'){
              item.value=res.preOwnerName;
              item.value1=res.preOwnerName;
            }else if(item.name=='原车主身份证号/机构代码证'){
              item.value=res.preOwnerId;
              item.value1=res.preOwnerId;//接口返回是小写，付款是大写，传值过去是要大写
            }else if(item.name=='经销商编号'){
              item.value=res.dealerCode;
              item.value1=res.dealerCode;
            }else if(item.name=='起拍时间'){
              item.value=res.biddingStartTime;
              item.value1=res.biddingStartTime;
            }else if(item.name=='拍卖结束时间'){
              item.value=res.biddingEndTime;
              item.value1=res.biddingEndTime;
            }else if(item.name=='检测Final报告URL'){
              item.value=res.inspFinalRptUrl;
              item.value1=res.inspFinalRptUrl;
            }else if(item.name=='检测Final报告名称'){
              item.value=res.inspFinalRptName;
              item.value1=res.inspFinalRptName;
            }else if(item.name=='检测Final报告下载地址'){
              item.value=res.inspFinalRptDlUrl;
              item.value1=res.inspFinalRptDlUrl;
            }else if(item.name=='拍卖结果URL'){
              item.value=res.aucRptUrl;
              item.value1=res.aucRptUrl;
            }else if(item.name=='拍卖结果名称'){
              item.value=res.aucRptName;
              item.value1=res.aucRptName;
            }else if(item.name=='拍卖结果下载地址'){
              item.value=res.aucRptDlUrl;
              item.value1=res.aucRptDlUrl;
            }
          }
        }
      })
    },
    getPaymentVoucherAndDetails(){//获取发送给宝马的付款凭证信息
      let query={
        taskId:this.taskId,
        vin:this.vin
      }
      this.$api.getPaymentVoucherAndDetails(query).then(data=>{
        if(data.code==0){
          let res=JSON.parse(data.data);
          this.invoiceDate=res.invoiceDate;
          for(let i=0;i<this.listTest2.length;i++){
            let item=this.listTest2[i]
            if(item.name=='检测编号'){
              item.value=res.inspectionNo;
              item.value1=res.inspectionNo;
            }else if(item.name=='拍卖编号'){
              item.value=res.biddingCode;
              item.value1=res.biddingCode;
            }else if(item.name=='成交状态'){
              item.value=res.auctionStatus;
              item.value1=res.auctionStatus;
            }else if(item.name=='交易发票日期'){
              item.value=res.invoiceDate;
              item.value1=res.invoiceDate;
            }else if(item.name=='付款状态'){
              item.value=res.paymentStatus;
              item.value1=res.paymentStatus;
            }else if(item.name=='是否在经销商名下'){
              item.value=res.transferDealer;
              item.value1=res.transferDealer;
            }else if(item.name=='原车主类型'){
              item.value=res.preOwnerType;
              item.value1=res.preOwnerType;
            }else if(item.name=='原车主姓名/公司名称'){
              item.value=res.preOwnerName;
              item.value1=res.preOwnerName;
            }else if(item.name=='原车主身份证号/机构代码证'){
              item.value=res.preOwnerID;
              item.value1=res.preOwnerID;
            }else if(item.name=='买家付款时间'){
              item.value=res.buyerPayTime;
              item.value1=res.buyerPayTime;
            }else if(item.name=='买家付款金额'){
              item.value=res.buyerPayAmount;
              item.value1=res.buyerPayAmount;
            }else if(item.name=='支付经销商时间'){
              item.value=res.payDlTime;
              item.value1=res.payDlTime;
            }else if(item.name=='保留价'){
              item.value=res.reservePrice;
              item.value1=res.reservePrice;
            }else if(item.name=='交易过户日期'){
              item.value=res.retailTransferDate;
              item.value1=res.retailTransferDate;
            }else if(item.name=='支付金额'){
              item.value=res.payAmount;
              item.value1=res.payAmount;
            }
            for(let j=0;j<res.payDlUrl.length;j++){
              let its=res.payDlUrl[j]
              if(item.name===its.voucherName){
               item.value=its.voucherUrl;
              }
            }
          }
        }
      })
    },
    getSendReturnList() {//列表
      let query={
        // curPage: 1,
        // pageSize: 10, 
        taskId:this.taskId,
      }
     this.$api.getSendReturnList(this.$$.delete_null_properties(query)).then(res => {
        if (res) {
          if (res.code == 0) {
            this.res = res.data.records.length>0?res.data.records[0]:{};
          } else {
            this.res ={}
          }
        }
      });
    },
    formatterReturn(column){
      let returnInfo=this.res.returnInfo?JSON.parse(this.res.returnInfo):[]
      if(returnInfo.length>0){
        let str=[]
        let stt=[]
        for(let i=0;i<returnInfo.length;i++){
          if(column.label==="退回类型"){
            if(returnInfo[i].returnType==0){
              str.push('其他信息退回')
            }else if(returnInfo[i].returnType==1){
              str.push('凭证退回')
            }else if(returnInfo[i].returnType==2){
              str.push('身份信息退回')
            }else if(returnInfo[i].returnType==3){
              str.push('时间信息退回')
            }
          }else{
            stt.push(returnInfo[i].returnReason)
          }
        }
        if(column.label==="退回类型"){
          return String(str)
        }else{
          return String(stt)
        }
      }
    },
    onUpload(row){
      this.imgRow=row;
    },
    requestUpload(file) {
      // console.log(this.imgRow,'===555===',file,this.fileList)
      let obj = new FormData();
      let code='01';
      switch(this.imgRow.name){
        case '资金往来凭证':
          code='01';
          break;
        case '二手车发票':
          code='02';
          break;
        case '过户后二手车登记证首页':
          code='03';
          break;
        case '过户后二手车登记证过户页':
          code='04';
          break;
      }
      obj.append("file", file.raw);
      obj.append("code", code);
      obj.append("name", this.imgRow.name);

      this.$api.postBmwFileUpload(obj).then(res => {
        if (res.code == 0) {
          for(let i=0;i<this.listTest2.length;i++){
            let item=this.listTest2[i]
            if(code===res.data.voucherCode&&this.imgRow.name===item.name){
              item.value=res.data.voucherUrl;
              console.log("88888")
            }
            console.log(item,item.name)
            switch(item.name){
              case '资金往来凭证':
                this.fileList[0].voucherUrl=res.data.voucherUrl;
                this.fileList[0].voucherSize=res.data.voucherSize;
                this.fileList[0].voucherType=res.data.voucherType;
                // this.fileList[0].voucherName=res.data.voucherName;
                // this.fileList[0].voucherCode=res.data.voucherCode;
                break;
              case '二手车发票':
                this.fileList[1].voucherUrl=res.data.voucherUrl;
                this.fileList[1].voucherSize=res.data.voucherSize;
                this.fileList[1].voucherType=res.data.voucherType;
                // this.fileList[1].voucherName=res.data.voucherName;
                // this.fileList[1].voucherCode=res.data.voucherCode;
                break;
              case '过户后二手车登记证首页':
                this.fileList[2].voucherUrl=res.data.voucherUrl;
                this.fileList[2].voucherSize=res.data.voucherSize;
                this.fileList[2].voucherType=res.data.voucherType;
                // console.log('===66666===')
                // this.fileList[2].voucherName=res.data.voucherName;
                // this.fileList[2].voucherCode=res.data.voucherCode;
                break;
              case '过户后二手车登记证过户页':
                this.fileList[3].voucherUrl=res.data.voucherUrl;
                this.fileList[3].voucherSize=res.data.voucherSize;
                this.fileList[3].voucherType=res.data.voucherType;
                // this.fileList[3].voucherName=res.data.voucherName;
                // this.fileList[3].voucherCode=res.data.voucherCode;
                break;
            }
          }

            // console.log(this.fileList,'===this.fileList==')
          this.$message.success(res.message)
          
        }
      });
    },
    onSubmit(){
      let arr=[];
      let arr1=[];
      let arr2=[];
      let inspectionInfo={};//检测信息
      let auctionInfoInfo={};//竞拍信息
      let paymentInfo={};//付款信息
      let sendReturnInfo='';//退回原因信息
      for(let i=0;i<this.listTest.length;i++){
        let item =this.listTest[i]
        if(item.checked){
          arr.push(item)
        }
      }
      for(let i=0;i<this.listTest1.length;i++){
        let item =this.listTest1[i]
        if(item.checked){
          arr1.push(item)
        }
      }
      for(let i=0;i<this.listTest2.length;i++){
        let item =this.listTest2[i]
        if(item.checked){
          arr2.push(item)
        }
      }
      if(arr.length===0&&arr1.length===0&&arr2.length===0){
        this.$message.warning('修改项至少选择一项！')
        return
      }
      for(let i=0;i<arr.length;i++){
        let item=arr[i]
        if(item.name=='检测编号'){
          inspectionInfo.inspectionNo=item.value;
        }else if(item.name=='车牌号'){
          inspectionInfo.licenseNo=item.value;
        }else if(item.name=='上牌时间'){
          inspectionInfo.regDate=item.value;
        }else if(item.name=='品牌'){
          inspectionInfo.brand=item.value;
        }else if(item.name=='车系'){
          inspectionInfo.series=item.value;
        }else if(item.name=='车型'){
          inspectionInfo.modelNameCN=item.value;
        }else if(item.name=='车款'){
          inspectionInfo.variantNameCN=item.value;
        }else if(item.name=='出厂日期'){
          inspectionInfo.prodDate=item.value;
        }else if(item.name=='动力类型'){
          inspectionInfo.powerType=item.value;
        }else if(item.name=='里程'){
          inspectionInfo.milage=item.value;
        }else if(item.name=='过户日期'){
          inspectionInfo.transferDate=item.value;
        }else if(item.name=='车龄月'){
          inspectionInfo.age=item.value;
        }else if(item.name=='是否在经销商名下'){
          inspectionInfo.transferDealer=item.value;
        }else if(item.name=='检测报告URL'){
          inspectionInfo.inspRptUrl=item.value;
        }else if(item.name=='检测报告名称'){
          inspectionInfo.inspRptName=item.value;
        }else if(item.name=='检测报告下载地址'){
          inspectionInfo.inspRptDlUrl=item.value;
        }else if(item.name=='下单来源'){
          inspectionInfo.taskSource=item.value;
        }else if(item.name=='检测时间'){
          inspectionInfo.inspectionTime=item.value;
        }
      }
      for(let i=0;i<arr1.length;i++){
        let item=arr1[i]
        if(item.name=='检测编号'){
          auctionInfoInfo.inspectionNo=item.value;
        }else if(item.name=='成交状态'){
          auctionInfoInfo.auctionStatus=item.value;
        }else if(item.name=='起拍价'){
          auctionInfoInfo.biddingStartPrice=item.value;
        }else if(item.name=='最高出价'){
          auctionInfoInfo.highestBid=item.value;
        }else if(item.name=='拍卖成交价'){
          auctionInfoInfo.auctionPrice=item.value;
        }else if(item.name=='出价次数'){
          auctionInfoInfo.numOfBids=item.value;
        }else if(item.name=='参拍人数'){
          auctionInfoInfo.auctionNumber=item.value;
        }else if(item.name=='拍卖编号'){
          auctionInfoInfo.biddingCode=item.value;
        }else if(item.name=='拍卖佣金'){
          auctionInfoInfo.auctionCommission=item.value;
        }else if(item.name=='是否在经销商名下'){
          auctionInfoInfo.transferDealer=item.value;
        }else if(item.name=='原车主类型'){
          auctionInfoInfo.preOwnerType=item.value;
        }else if(item.name=='原车主姓名/公司名称'){
          auctionInfoInfo.preOwnerName=item.value;
        }else if(item.name=='原车主身份证号/机构代码证'){
          auctionInfoInfo.preOwnerID=item.value;
        }else if(item.name=='经销商编号'){
          auctionInfoInfo.dealerCode=item.value;
        }else if(item.name=='起拍时间'){
          auctionInfoInfo.biddingStartTime=item.value;
        }else if(item.name=='拍卖结束时间'){
          auctionInfoInfo.biddingEndTime=item.value;
        }else if(item.name=='检测Final报告URL'){
          auctionInfoInfo.inspFinalRptUrl=item.value;
        }else if(item.name=='检测Final报告名称'){
          auctionInfoInfo.inspFinalRptName=item.value;
        }else if(item.name=='检测Final报告下载地址'){
          auctionInfoInfo.inspFinalRptDlUrl=item.value;
        }else if(item.name=='拍卖结果URL'){
          auctionInfoInfo.aucRptUrl=item.value;
        }else if(item.name=='拍卖结果名称'){
          auctionInfoInfo.aucRptName=item.value;
        }else if(item.name=='拍卖结果下载地址'){
          auctionInfoInfo.aucRptDlUrl=item.value;
        }
      }
      for(let i=0;i<arr2.length;i++){
        let item=arr2[i]
        if(item.name=='检测编号'){
          paymentInfo.inspectionNo=item.value;
        }else if(item.name=='拍卖编号'){
          paymentInfo.biddingCode=item.value;
        }else if(item.name=='成交状态'){
          paymentInfo.auctionStatus=item.value;
        }else if(item.name=='交易发票日期'){
          paymentInfo.invoiceDate=item.value;
        }else if(item.name=='付款状态'){
          paymentInfo.paymentStatus=item.value;
        }else if(item.name=='是否在经销商名下'){
          paymentInfo.transferDealer=item.value;
        }else if(item.name=='原车主类型'){
          paymentInfo.preOwnerType=item.value;
        }else if(item.name=='原车主姓名/公司名称'){
          paymentInfo.preOwnerName=item.value;
        }else if(item.name=='原车主身份证号/机构代码证'){
          paymentInfo.preOwnerID=item.value;
        }else if(item.name=='买家付款时间'){
          paymentInfo.buyerPayTime=item.value;
        }else if(item.name=='买家付款金额'){
          paymentInfo.buyerPayAmount=item.value;
        }else if(item.name=='支付经销商时间'){
          paymentInfo.payDlTime=item.value;
        }else if(item.name=='保留价'){
          paymentInfo.reservePrice=item.value;
        }else if(item.name=='交易过户日期'){
          paymentInfo.retailTransferDate=item.value;
        }else if(item.name=='支付金额'){
          paymentInfo.payAmount=tem.value;
        }
      }

      let payDlUrl=[]
      for(let i=0;i<this.fileList.length;i++){
        let item=this.fileList[i]
        if(item.voucherUrl){
          payDlUrl.push(item)
        }
      }
      if(payDlUrl.length>0){
        paymentInfo.payDlUrl=payDlUrl;
      }
      // console.log(payDlUrl,'===payDlUrl===',this.fileList)
      // console.log(inspectionInfo,'==检测信息===',auctionInfoInfo,paymentInfo)
      
      this.$confirm('请确认是否发送修改结果?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let query={
          taskId:this.taskId,
          vin:this.vin,
          inspectionInfo:JSON.stringify(inspectionInfo)==="{}"?'':inspectionInfo,//检测信息
          auctionInfoInfo:JSON.stringify(auctionInfoInfo)==="{}"?'':auctionInfoInfo,//竞拍信息
          paymentInfo:JSON.stringify(paymentInfo)==="{}"?'':paymentInfo,//付款信息
          // sendReturnInfo:sendReturnInfo,//退回原因信息
        }
        this.$api.pushReceiveReturnInfo(this.$$.delete_null_properties(query)).then(res=>{
          if(res.code==0){
            this.initData()
            this.$message.success(res.message)
            // if(res.data===true){
            //   this.$message.success(res.message)
            // }else{
            //   this.$message.error(res.data)
            // }
          }
        })
      }).catch(() => {});
    },
    closeViewer() {
      this.showViewer = false
    },
    handleSizeChange(row){
      this.page_size=row;
      this.page_index=1;
      this.getReceiveReturnLogList()
    },
    handleCurrentChange(row){
      this.page_index=row;
      this.getReceiveReturnLogList()
    },
    getReceiveReturnLogList(){//获取推送修复数据的日志列表
      let query={
        taskId:this.taskId,
        vin:this.vin
      }
      this.$api.getReceiveReturnLogList(query).then(res=>{
        if (res.code == 0) {
          this.loglist = res.data.records;
          this.page_total = res.data.total;
        } else {
          this.loglist = [];
          this.page_total = 0;
        }
      })
    },
    getReceiveReturnLogById(){//获取推送修复数据日志详情，根据ID
      this.$api.getReceiveReturnLogById().then(res=>{
        if(res.code==0){
          this.loglist=res.data.records;
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.contenPageBox{
  padding: 20px 40px 140px;
  .imgRowBox{
    margin:10px 10px 5px;
    width: 200px;
    .imgBox{
      background: #ededed;
      width: 200px;
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      p{
        cursor: pointer;
      }
      .el-image{
        width: 200px;
        height: 150px;
        ::v-deep .image-slot{
          display: flex;
          justify-content: center;
          align-items: center;
          height: 150px;
        }
      }
    }
    p{padding:5px 0}
  }
  .tableLine th:nth-of-type(1),
  .tableLine tbody td:nth-of-type(1){
    width: 100px;
  }
  .tableLine th:nth-of-type(2),
  .tableLine tbody td:nth-of-type(2){
    width: 300px;
  }
  .imgRowBox{
    margin:10px 10px 5px;
    width: 200px;
    .imgBox{
      background: #ededed;
      width: 200px;
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      p{
        cursor: pointer;
      }
      .el-image{
        width: 200px;
        height: 150px;
        ::v-deep .image-slot{
          display: flex;
          justify-content: center;
          align-items: center;
          height: 150px;
        }
      }
    }
    p{padding:5px 0}
  }
  .ImgBox{
    width:150px;
    // height:100px;
  }

}
.tableLine thead th:nth-of-type(3),
 #app .tableLine tbody tr td:nth-of-type(3){
    text-align:left ;
    padding: 0 10px;
}

</style>
