<template>
  <!-- 成交预约 -->
  <div class="contentPageBox">
    <div class="ckxTitle">投保商户信息</div>
    <el-table :data="marchantlist" border stripe class="table-mainbg mt20">
      <el-table-column prop="merchantId" label="投保商户ID" align="center" ></el-table-column>
      <el-table-column prop="merchantName" label="投保商户姓名" align="center" >
        <template slot-scope="scope">{{scope.row.merchant?scope.row.merchant.merchantName:scope.row.merchantName}}</template>
      </el-table-column>
      <el-table-column prop="registerCityName" label="注册城市" align="center">
        <template slot-scope="scope">{{scope.row.merchant?scope.row.merchant.registerCityName:scope.row.registerCityName}}</template>
      </el-table-column>
      <el-table-column prop="businessCityName" label="经营城市" align="center">
        <template slot-scope="scope">{{scope.row.merchant?scope.row.merchant.businessCityName:scope.row.businessCityName}}</template>
      </el-table-column>
      <el-table-column prop="maintainerName" label="维护专员" align="center">
        <template slot-scope="scope">{{scope.row.merchant?scope.row.merchant.maintainerName:scope.row.maintainerName}}</template>
      </el-table-column>
    </el-table>

    <div class="ckxTitle mt30">投保信息</div>
    <el-table :data="querylist" border stripe class="table-mainbg mt20">
      <el-table-column prop="insureNo" label="保单号" align="center" ></el-table-column>
      <el-table-column prop="name" label="开通险种" align="center" >
        <template slot-scope="scope">{{scope.row.insure?scope.row.insure.name:scope.row.name}}</template>
      </el-table-column>
      <el-table-column prop="createDate" label="开通时间" align="center"></el-table-column>
      <el-table-column prop="enableTime" label="生效时间" align="center"></el-table-column>
      <el-table-column prop="disableTime" label="截止时间" align="center"></el-table-column>
      <el-table-column prop="insureStatus" label="保单状态" align="center">
       <template slot-scope="scope">{{scope.row.insureStatus==1?'待生效':scope.row.insureStatus===2?'生效中':scope.row.insureStatus==3?'已失效':scope.row.insureStatus==4?'无效':scope.row.insureStatus}}</template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      marchantlist: [],//
      merchantId:'',//列表传过来的id
      querylist:[],
    }
  },
  created(){
    if(this.$route.query.id) this.merchantId = Number(this.$route.query.id);
    this.getListByMerchantId()
  },
  methods: {
    getListByMerchantId(id){//根据商户成交预约Id获取商户预约详情
      let query={
        merchantId:this.merchantId
      }
      this.$api.getListByMerchantId(query).then(res=>{
        if(res.code==0){
          if(res.data){
            this.marchantlist=res.data.length>0?[res.data[0]]:[];
            this.querylist=res.data;
          }
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.contentPageBox{
  padding:40px
}
</style>